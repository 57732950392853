import {
  Table,
  Card,
  Text,
  Button,
  Input,
  Loading,
  Spinner,
} from "@nextui-org/react";
import { useState } from "react";
import {
  AiFillPrinter,
  AiOutlineSearch,
  AiOutlineOrderedList,
  AiOutlineSortAscending,
  AiFillFileExcel,
} from "react-icons/ai";
import useWindowWide from "../hooks/useWindowWide";
import { jsPDF } from "jspdf";
import { Fragment } from "react";
import { useEffect } from "react";
import DownloadButton from "./download-button";
import DeleteButton from "./delete-button";
import DeleteModal from "./delete-modal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import * as Xlsx from "xlsx";

const columns = [
  { key: "date", label: "Date" },
  { key: "orderName", label: "Name" },
  { key: "productName", label: "Product" },
  { key: "details", label: "Details" },
  { key: "price", label: "Price" },
  { key: "codes", label: "Tokens" },
  { key: "pdf", label: "" },
];

const OrderTable = (props) => {
  const [row, setRow] = useState(10);
  const currentWidth = useWindowWide(860);
  const [page, setPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (props?.list?.length <= row) {
      setPage(1);
    }
  }, [props.list]);

  const downloadExcel = () => {
    const data = props.list.map((doc) => {
      return {
        Date: doc.date.slice(0, 10),
        Name: doc.orderName,
        Product: doc.productName,
        Details: doc.details,
        Price: doc.price,
        IMEIs: doc.codes?.length,
        IMEIDetail: doc?.codes?.join(", "),
      };
    });

    const workSheet = Xlsx.utils.json_to_sheet(data);
    const workBook = Xlsx.utils.book_new();
    Xlsx.utils.book_append_sheet(workBook, workSheet, "Sheet1");
    Xlsx.writeFile(workBook, `exports.xlsx`);
  };

  const downloadExcel2 = () => {
    const data = props.list
      .map((doc) => {
        return doc?.codes?.map((code) => {
          return {
            Date: doc.date.slice(0, 10),
            Name: doc.orderName,
            Product: doc.productName,
            Details: doc.details,
            Price: doc.price,
            IMEI: code,
          };
        });
      })
      .flat();

    const workSheet = Xlsx.utils.json_to_sheet(data);
    const workBook = Xlsx.utils.book_new();
    Xlsx.utils.book_append_sheet(workBook, workSheet, "Sheet1");
    Xlsx.writeFile(workBook, `exports.xlsx`);
  };

  return (
    <>
      <Card variant="bordered" className="cardShadow">
        <Card.Body css={{ p: 0 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 10,
              padding: 16,
              flexWrap: "wrap",
            }}
          >
            <div className="orderTableActions">
              <Input
                aria-label="Set Row"
                contentLeft={<AiOutlineOrderedList />}
                type="number"
                bordered
                css={{ w: "100px" }}
                size="sm"
                value={row}
                onChange={(event) => setRow(event.target.value)}
              />
              {/* <Button size="sm" color="success" auto rounded>
            <AiFillPrinter />
            &nbsp;PDF
          </Button> */}
              {currentWidth && (
                <Text
                  b
                  css={{
                    br: "20px",
                    bgColor: "$pink100",
                    py: "5px",
                    px: "10px",
                  }}
                  color="$pink800"
                  size="15px"
                >
                  All Products
                </Text>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 12,
                flexWrap: "wrap",
              }}
            >
              <DateRangePicker
                className="dateRangePicker"
                onChange={props.setDate}
                value={props.date}
              />
              <Input
                aria-label="Search"
                contentLeft={<AiOutlineSearch />}
                type="text"
                placeholder="Search..."
                bordered
                size="sm"
                value={props.value}
                onChange={(event) => props.setValue(event.target.value)}
                clearable
              />
              <Button
                auto
                size="sm"
                color="success"
                icon={<AiFillFileExcel />}
                onClick={downloadExcel2}
              />
              <Button
                auto
                size="sm"
                color="error"
                icon={<AiFillFileExcel />}
                onClick={downloadExcel}
              />
            </div>
          </div>
          <Table aria-label="Customers List" compact sticked shadow={false}>
            <Table.Header>
              {columns.map((column) => {
                return (
                  <Table.Column key={column.key} css={{ color: "black" }}>
                    <div className="tableColumn">
                      {column.label}&nbsp;&nbsp;&nbsp;
                      {column.key !== "pdf" && (
                        <Button
                          flat
                          auto
                          size="xs"
                          rounded
                          onClick={() =>
                            props.sortFunction(column.key, !props.asc)
                          }
                        >
                          <AiOutlineSortAscending />
                        </Button>
                      )}
                    </div>
                  </Table.Column>
                );
              })}
            </Table.Header>
            <Table.Body css={{ textAlign: "start" }}>
              {props?.list?.length > 0 &&
                props.list.map((doc) => {
                  return (
                    <Table.Row key={doc._id} css={{ fontSize: "14px" }}>
                      <Table.Cell>{doc.date.slice(0, 10)}</Table.Cell>
                      <Table.Cell>{doc.orderName}</Table.Cell>
                      <Table.Cell>{doc.productName}</Table.Cell>
                      <Table.Cell>{doc.details}</Table.Cell>
                      <Table.Cell>{doc.price}</Table.Cell>
                      <Table.Cell>{doc.codes.length}</Table.Cell>
                      <Table.Cell>
                        <div style={{ display: "flex", gap: "4px" }}>
                          <DownloadButton
                            id={doc._id}
                            product={doc.productName}
                            link={`https://sca-token-api.vercel.app/pdf/${doc._id}`}
                          />
                          <DeleteButton
                            id={doc._id}
                            product={doc.productName}
                            setOpen={setOpen}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
            <Table.Pagination
              size="sm"
              color="error"
              shadow
              align="center"
              rowsPerPage={row}
              page={page}
              initialPage={1}
              onPageChange={(page) => setPage(page)}
              total={Math.round(props?.list?.length / row)}
            />
          </Table>
        </Card.Body>
      </Card>
      <DeleteModal
        visible={open}
        closeHandler={() => setOpen(false)}
        refetch={props.refetch}
      />
    </>
  );
};

export default OrderTable;
